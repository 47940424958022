import { FC, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Preloader from "./components/preloader";
import moment from 'moment';
import 'moment/locale/fr'
moment.locale("fr")

const DashboardOne = lazy(() => import("./pages/dashboard-one"));
const DashboardTwo = lazy(() => import("./pages/dashboard-two"));
const DashboardThree = lazy(() => import("./pages/dashboard-three"));
const DashboardFour = lazy(() => import("./pages/dashboard-four"));
const DashboardFive = lazy(() => import("./pages/dashboard-five"));
const Profile = lazy(() => import("./pages/profile"));
const Invoice = lazy(() => import("./pages/invoice"));
const Contact = lazy(() => import("./pages/contact"));
const FileManager = lazy(() => import("./pages/file-manager"));
const Calendar = lazy(() => import("./pages/calendar"));
const Patients = lazy(() => import("./pages/patients"));
const DetailPatient = lazy(() => import("./pages/detailPatient"));
const Timeline = lazy(() => import("./pages/timeline"));
const Pricing01 = lazy(() => import("./pages/pricing-01"));
const Pricing02 = lazy(() => import("./pages/pricing-02"));
const Pricing03 = lazy(() => import("./pages/pricing-03"));
const Signin = lazy(() => import("./pages/signin"));
const Signin02 = lazy(() => import("./pages/signin-02"));
const Signup = lazy(() => import("./pages/signup"));
const Signup02 = lazy(() => import("./pages/signup-02"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));

const App: FC = () => {
    return (
        <Router>
            <Suspense fallback={<Preloader />}>
                <Routes>
                    {/* Classic Routes */}
                    <Route path="/login" element={<Signin />} />


                    {/* Dashboard Routes */}
                    <Route path="/" element={<Navigate to="/patients" />} />

                    {/* Calandar Routes */}
                    <Route path="/calandar" element={<Calendar />} />

                    {/* Patients Routes */}
                    <Route path="/patients" element={<Patients />} />
                    <Route path="/detail/:id" element={<DetailPatient />} />




                    {/* Error Pages */}

                    <Route path="/error-500" element={<Error500 />} />
                    <Route path="/error-503" element={<Error503 />} />
                    <Route path="/error-505" element={<Error505 />} />

                    {/* 404 Page Route */}
                    <Route path="*" element={<ErrorNotFound />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default App;
